import React, { useState, useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CardDropdown as textLang, monthsList } from 'staticData/languages';
import AppContext from 'context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Alert, Card, Col, Row, Spinner } from 'react-bootstrap';
import DeleteBotModal from 'pages/common/modals/deleteBot';
import classNames from 'classnames';
import SimpleBarReact from 'simplebar-react';
import SuccessfullyDeleteBotModal from 'pages/common/modals/successfullyDeleteBot';
import { endpointInterfaceV2 } from 'services/endpointInterface/endpointInterfaceV2';
import SoftBadge from 'components/common/SoftBadge';
import { cleanCache } from 'services/cache';
import GeneralModal from '../modals/GeneralModal';
import IconButton from 'components/common/IconButton';
import PacModal from './modals/PacModal';
import { portfolioList } from 'pages/CreateBot/staticData/portfolioList';
import ButtonGrid from 'pages/CreateBot/ButtonGrid';
import checkPrimaryLight from 'assets/img/animated-icons/check-primary-light.json';
import Lottie from 'lottie-react';
import { walletImgRender } from 'services/coins/components';
import { coinsData, currencyMap } from 'services/coins/common';
import MultiBenchmark from 'pages/common/portfolioRow/modals/multiBenchmark/MultiBenchmark';
import Flex from 'components/common/Flex';

const CardDropdown = ({ portfolio, managed }) => {
  const {
    config: { lang, currency }
  } = useContext(AppContext);

  const { bot_details: bot } = portfolio;
  const managed_capital = managed;

  const { name, riskLevels, backendMinLevels, coins } = portfolioList.find(
    portfolio => String(portfolio.id) === String(bot.type)
  );
  const riskLevel = riskLevels[backendMinLevels.indexOf(bot.min_level)];

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [multiBenchmarkModalShow, setMultiBenchmarkModalShow] = useState(false);
  const [successfullyDeleteBotModalShow, setSuccessfullyDeleteBotModalShow] =
    useState(false);
  const [waitDelete, setWaitDelete] = useState(false);
  const [portfolioStatsModalShow, setPortfolioStatsModalShow] = useState(false);
  const [ordersModalShow, setOrdersModalShow] = useState(false);
  const [pacStatsModalShow, setPacStatsModalShow] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [orders, setOrders] = useState();
  const scrollBarRef = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [orderPage, setOrderPage] = useState(0);
  const [maxOrders, setMaxOrders] = useState();
  const [waitChangeStatus, setWaitChangeStatus] = useState(false);
  const [changeStatusSuccess, setchangeStatusSuccess] = useState(false);
  const [redirectTimer, setRedirectTimer] = useState(null);

  const handleSetStatus = async () => {
    setWaitDelete(true);
    let setStatusResponse = await endpointInterfaceV2({
      internalEndpoint: 'setBotStatus',
      httpMethod: 'post',
      usingCredentials: true,
      pathParams: {
        bot_id: bot.id
      },
      bodyParams: {
        status: 'TERMINATED',
        sell_all: '1'
      }
    });
    setWaitDelete(false);
    if (setStatusResponse.validResponse) {
      setShowDeleteModal(false);
      setSuccessfullyDeleteBotModalShow(true);
      await cleanCache(0);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const closeSuccessfullyDeleteBotModal = () => {
    setSuccessfullyDeleteBotModalShow(false);
  };

  const botStatusColor = status => {
    if (status?.toUpperCase() === 'ACTIVE') return 'success';
    if (status?.toUpperCase() === 'ERROR') return 'danger';
    else return 'warning';
  };

  const getDate = dateStr => {
    let date = new Date(dateStr);

    let day = date.getDate();
    let month = monthsList.monthsLong[lang][date.getMonth() || 0];
    let year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  const timeSince = date => {
    var dateInMilliseconds = new Date(date);
    var seconds = Math.floor((new Date() - dateInMilliseconds) / 1000);
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + textLang.years[lang];
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + textLang.months[lang];
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + textLang.days[lang];
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + textLang.hours[lang];
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + textLang.minutes[lang];
    }
    if (interval === 0) {
      return 'now';
    }
    return Math.floor(seconds) + textLang.seconds[lang];
  };

  const handleScroll = () => {
    const scrollNode = scrollBarRef.current?.getScrollElement();
    if (scrollNode) {
      const { scrollTop, scrollHeight, clientHeight } = scrollNode;
      if (scrollTop + clientHeight >= scrollHeight * 0.7) {
        setIsAtBottom(true);
      } else {
        setIsAtBottom(false);
      }
    }
  };

  const getBotOrders = async (updatePage = false) => {
    let resp = await endpointInterfaceV2({
      internalEndpoint: 'botOrders',
      httpMethod: 'get',
      usingCredentials: true,
      pathParams: {
        bot_id: portfolio.bot_details.id
      },
      queryParams: {
        page_index: updatePage ? orderPage + 1 : orderPage,
        page_size: 20
      }
    });
    if (resp.validResponse) {
      let newOrders = resp.data.orders;
      if (updatePage) {
        setOrders(orders.concat(newOrders));
        setOrderPage(orderPage + 1);
      } else {
        setMaxOrders(resp.data.count);
        setOrders(newOrders);
      }
    } else setOrders([]);
  };

  useEffect(() => {
    if (isAtBottom && orders.length < maxOrders) {
      getBotOrders(true);
    }
  }, [isAtBottom]);

  useEffect(async () => {
    try {
      if (!loaded) {
        getBotOrders();
        setLoaded(true);
      }
    } catch (error) {
      console.error(error.message);
    }
  }, []);

  const restartPortfolio = async () => {
    setWaitChangeStatus(true);
    let setStatusResponse = await endpointInterfaceV2({
      internalEndpoint: 'setBotStatus',
      httpMethod: 'post',
      usingCredentials: true,
      pathParams: {
        bot_id: bot.id
      },
      bodyParams: {
        status: 'ACTIVE'
      }
    });
    if (setStatusResponse.validResponse) {
      setchangeStatusSuccess(true);
      await cleanCache(0);
      const timer = setTimeout(() => {
        window.location.reload();
      }, 5000);
      setRedirectTimer(timer);
    }
    setWaitChangeStatus(false);
  };

  const handleCloseClickTimer = () => {
    if (redirectTimer) {
      clearTimeout(redirectTimer);
    }
    window.location.reload();
  };

  // del timer during return
  useEffect(() => {
    return () => {
      if (redirectTimer) {
        clearTimeout(redirectTimer);
      }
    };
  }, [redirectTimer]);

  const portfolioStatsData = {
    button: {
      text: textLang.close[lang],
      variant: 'primary',
      icon: 'times',
      buttonFunction: () =>
        !changeStatusSuccess
          ? setPortfolioStatsModalShow(false)
          : handleCloseClickTimer()
    },
    head: textLang.info[lang],
    body: !changeStatusSuccess ? (
      <>
        <h5 className="mb-2">{name}</h5>
        {bot.status.toUpperCase() === 'ERROR' && bot?.status_info && (
          <Alert variant="danger">
            <p
              className="mb-0 fs--1"
              dangerouslySetInnerHTML={{
                __html: bot.status_info[lang]
              }}
            ></p>
          </Alert>
        )}
        <p className="d-flex align-items-center">
          <b>{textLang.state[lang]}</b>:{' '}
          <SoftBadge pill bg={botStatusColor(bot?.status)} className="ms-2">
            {bot?.status?.toUpperCase() === 'ACTIVE'
              ? textLang.active[lang]
              : bot?.status?.toUpperCase() === 'PAUSED'
              ? textLang.paused[lang]
              : textLang.error[lang]}
          </SoftBadge>
          {bot?.status.toUpperCase() === 'ERROR' && (
            <IconButton
              variant={'primary'}
              className="ms-2"
              icon={waitChangeStatus ? '' : 'play'}
              disabled={waitChangeStatus}
              iconAlign="left"
              transform="shrink-3"
              onClick={() => restartPortfolio()}
            >
              {waitChangeStatus && (
                <Spinner
                  className="me-2 mt-1"
                  style={{ width: '1rem', height: '1rem' }}
                />
              )}
              {textLang.restart[lang]}
            </IconButton>
          )}
        </p>
        <p className="d-flex align-items-center">
          <b>{textLang.assets[lang]}</b>:{' '}
          {walletImgRender(coins, 'ms-2', true, 's', null)}
        </p>
        <p className="d-flex align-items-center">
          <b>{textLang.risks[lang]}</b>:{' '}
          <ButtonGrid
            actives={[riskLevel]}
            click={false}
            buttonSize={'sm'}
            className="ms-2"
          />
        </p>
        <p>
          <b>{textLang.creationDate[lang]}</b>:{' '}
          <span className="text-dark">
            {bot?.created_at ? getDate(bot?.created_at) : 'n.d.'}
          </span>
        </p>
        <p>
          <b>{textLang.startValue[lang]}</b>:{' '}
          <span className="text-dark">
            {currency}
            {bot.initial_eqv[currencyMap[currency]].toFixed(2) || 'n.d.'}
          </span>
        </p>
        {portfolio.added_capital != null && (
          <p>
            <b>{textLang.addedCapital[lang]}</b>:{' '}
            <span className="text-dark">
              {currency}
              {portfolio.added_capital.toFixed(2) || 'n.d.'}
            </span>
          </p>
        )}
        <p>
          <b>{textLang.underManagement[lang]}</b>:{' '}
          <span className="text-dark">
            {currency}
            {managed_capital || 'n.d.'}
          </span>
        </p>
        {portfolio?.indicators?.sharpe > 0 && (
          <p>
            <b>Sharpe Ratio</b>:{' '}
            <span className="text-dark">{portfolio?.indicators?.sharpe}</span>
          </p>
        )}
      </>
    ) : (
      <Card.Body>
        <Row className="align-items-center">
          <Col lg={12} className="d-flex justify-content-center">
            <Lottie
              animationData={checkPrimaryLight}
              loop={true}
              style={{ height: '120px', width: '120px' }}
            />
          </Col>
        </Row>
        <h4
          className="text-center font-weight-bold"
          dangerouslySetInnerHTML={{
            __html: textLang.changeStatusText[lang]
          }}
        ></h4>
      </Card.Body>
    )
  };

  const ordersStatsData = {
    button: {
      text: textLang.close[lang],
      variant: 'primary',
      icon: 'times',
      buttonFunction: () => setOrdersModalShow(false)
    },
    head: `${textLang.orderHistory[lang]} ${name}`,
    body: (
      <SimpleBarReact
        style={{ maxHeight: '20rem' }}
        ref={scrollBarRef}
        scrollableNodeProps={{ onScroll: handleScroll }}
      >
        <Card.Body className="ps-2">
          {orders &&
            orders.map((order, index) => {
              const isGraphicallyFirst = index === 0;
              const isGraphicallyLast = index === orders.length - 1;
              const coin = order.coin.asset || null;
              const quote = order.coin.quote || null;
              const eqvPrice = order.executed_price
                ? order.executed_base_qty * order.executed_price
                : order?.eqv[currencyMap[currency]];

              return (
                <Row
                  key={index}
                  className={classNames(
                    'g-3 recent-activity-timeline recent-activity-timeline-primary',
                    {
                      'pb-card': !isGraphicallyLast,
                      'recent-activity-timeline-current': isGraphicallyFirst,
                      'recent-activity-timeline-past': !isGraphicallyFirst
                    }
                  )}
                >
                  <Col xs="auto" className="ps-4 ms-2">
                    <div className="ps-2">
                      <div className="icon-item icon-item-sm rounded-circle bg-200 shadow-none">
                        <FontAwesomeIcon
                          icon={order.side === 'BUY' ? 'plus' : 'minus'}
                          className={`text-${
                            order.side === 'BUY' ? 'success' : 'danger'
                          }`}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <Row
                      className={classNames('g-3', {
                        'border-bottom pb-card': !isGraphicallyLast
                      })}
                    >
                      <Col>
                        <Flex className="mb-1 align-items-center">
                          {coin && (
                            <img
                              key={index}
                              src={coinsData[coin].img}
                              alt={coinsData[coin].name}
                              style={{
                                height: '15px'
                              }}
                            />
                          )}
                          <h6 className="text-800 mb-0 ms-2">{`${
                            order.side === 'BUY'
                              ? textLang.buy[lang]
                              : textLang.sell[lang]
                          } ${order.executed_base_qty} ${coin} ${
                            '($' + eqvPrice.toFixed(2) + ')'
                          }`}</h6>
                        </Flex>
                        {coin && (
                          <p className="fs--1 text-600 mb-0">
                            {`${coin}-${quote}`}
                          </p>
                        )}
                      </Col>
                      <Col xs="auto">
                        <p className="fs--2 text-500 mb-0">
                          {timeSince(order.closing_ts)}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );
            })}
          {orders && orders.length === 0 && <h6>{textLang.noOrders[lang]}</h6>}
        </Card.Body>
      </SimpleBarReact>
    )
  };

  return (
    bot && (
      <>
        {/* PORTFOLIO Statistics */}
        <GeneralModal
          showModal={portfolioStatsModalShow}
          setShowModal={
            !changeStatusSuccess
              ? () => setPortfolioStatsModalShow()
              : () => handleCloseClickTimer()
          }
          button={portfolioStatsData.button}
          head={portfolioStatsData.head}
          body={portfolioStatsData.body}
        />
        {/* Bot Orders */}
        <GeneralModal
          showModal={ordersModalShow}
          setShowModal={setOrdersModalShow}
          button={ordersStatsData.button}
          head={ordersStatsData.head}
          body={ordersStatsData.body}
        />
        {/* PAC Statistics */}
        <PacModal
          showModal={pacStatsModalShow}
          setShowModal={setPacStatsModalShow}
          name={name}
          bot={bot}
        />
        <MultiBenchmark
          showModal={multiBenchmarkModalShow}
          setShowModal={setMultiBenchmarkModalShow}
          name={name}
          bot={bot}
        />
        {/* Delete BOT */}
        <DeleteBotModal
          deleteBotModalShow={showDeleteModal}
          setDeleteBotModalShow={setShowDeleteModal}
          confirmBotDeletion={handleSetStatus}
          bot={bot}
          waitDelete={waitDelete}
        />
        <SuccessfullyDeleteBotModal
          successfullyDeleteBotModalShow={successfullyDeleteBotModalShow}
          closeSuccessfullyDeleteBotModal={closeSuccessfullyDeleteBotModal}
          type={'portfolio'}
        />
        <Dropdown className="font-sans-serif btn-reveal-trigger" align="start">
          <Dropdown.Toggle
            variant="link"
            size="sm"
            data-boundary="viewport"
            className="text-600"
          >
            <FontAwesomeIcon icon="ellipsis-h" className="fs--2" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="border py-0">
            <div className="py-2">
              <Dropdown.Item
                className="text-info"
                onClick={() => setMultiBenchmarkModalShow(true)}
              >
                <b>{textLang.bot_statistics[lang]}</b>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setPortfolioStatsModalShow(true)}>
                {textLang.info[lang]}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setOrdersModalShow(true)}>
                {textLang.orders[lang]}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setPacStatsModalShow(true)}
                disabled={!bot.pac}
              >
                {textLang.pacDetails[lang]}
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                className="text-danger"
                onClick={() => setShowDeleteModal(true)}
              >
                {textLang.delete[lang]}
              </Dropdown.Item>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </>
    )
  );
};

CardDropdown.propTypes = {
  portfolio: PropTypes.object,
  managed: PropTypes.number
};

export default CardDropdown;

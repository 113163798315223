import { useContext } from 'react';
import AppContext from 'context/Context';
import { firstProTier, secondProTier } from 'staticData/common';
import { isInTestAB, portfolioTest } from 'services/testAB/functions';
import {
  changePriceAndLimitsDateOctober2024,
  afterChangePriceOctober2024
} from 'staticData/unlockTimers';

export const getPricing = (
  newProTier = false,
  isNewSub = false,
  pricingPage = false
) => {
  const { userInfos } = useContext(AppContext);
  const inTestABPortfolio = isInTestAB(userInfos, portfolioTest);

  const Pricing = [
    {
      title: 'Explorer',
      subTitle: {
        'en-EN': '',
        'it-IT': ''
      },
      proTier: firstProTier,
      price: [
        {
          value: afterChangePriceOctober2024 ? '17.99' : '14.99'
        },
        {
          value: afterChangePriceOctober2024 ? '11.99' : '9.99'
        }
      ],
      enabled: newProTier === 0 || newProTier === firstProTier,
      featureTitle: {
        'en-EN': '',
        'it-IT': ''
      },
      features: [
        ...(isNewSub && userInfos.trial_allowed
          ? [
              {
                title: {
                  'en-EN':
                    "7-day free trial. Don't worry, we'll notify you 24h before it ends",
                  'it-IT':
                    'Prova gratuita di 7 giorni. Non ti preoccupare, ti avviseremo 24 ore prima del termine'
                }
              }
            ]
          : []),
        ...(inTestABPortfolio
          ? [
              {
                title: {
                  'en-EN': 'AI Portfolio: Market Leaders, High Volume',
                  'it-IT': 'AI Portfolio: Market Leaders, High Volume'
                }
              }
            ]
          : []),
        {
          title: {
            'en-EN': '$10K investments limit',
            'it-IT': `Limite di ${
              new Date(userInfos?.created_at * 1000) <
              changePriceAndLimitsDateOctober2024
                ? 10
                : 5
            }K$ di investimento`
          }
        },
        ...(!inTestABPortfolio
          ? [
              {
                title: {
                  'en-EN': 'Up to 5 AI Agents active at the same time',
                  'it-IT': 'Fino a 5 AI Agents attivi contemporaneamente'
                }
              }
            ]
          : []),
        {
          title: {
            'en-EN': 'Real time news (Sentiment)',
            'it-IT': 'News in tempo reale (Sentiment)'
          }
        },
        {
          title: {
            'en-EN': '25K $ for Paper Trading (virtual mode)',
            'it-IT': 'Modalità Virtuale (25K$)'
          }
        }
      ],
      isYearlyPromo: true
    },
    {
      title: 'Investor',
      subTitle: {
        'en-EN': '',
        'it-IT': ''
      },
      proTier: secondProTier,
      isFeatured: true,
      price: [
        {
          value: afterChangePriceOctober2024 ? '26.99' : '32.49'
        },
        {
          value: afterChangePriceOctober2024 ? '17.99' : '24.99'
        }
      ],
      enabled: newProTier === 0 || newProTier === secondProTier,
      featureTitle: {
        'en-EN': '',
        'it-IT': ''
      },
      features: [
        ...(isNewSub && userInfos.trial_allowed
          ? [
              {
                title: {
                  'en-EN':
                    "7-day free trial. Don't worry, we'll notify you 24h before it ends",
                  'it-IT':
                    'Prova gratuita di 7 giorni. Non ti preoccupare, ti avviseremo 24 ore prima del termine'
                }
              }
            ]
          : []),
        ...(inTestABPortfolio
          ? [
              {
                title: {
                  'en-EN':
                    'AI Portfolio: Market Leaders, High Volume, Discovery Altcoins',
                  'it-IT':
                    'AI Portfolio: Market Leaders, High Volume, Discovery Altcoins'
                }
              }
            ]
          : []),
        {
          title: {
            'en-EN': '$25K investments limit',
            'it-IT': 'Limite di 25K$ di investimento'
          }
        },
        ...(!inTestABPortfolio
          ? [
              {
                title: {
                  'en-EN': 'AI Portfolio',
                  'it-IT': 'AI Portfolio'
                },
                beforeIcon: { name: 'star', variant: 'success' }
              },
              {
                title: {
                  'en-EN': 'Up to 10 AI Agents active at the same time',
                  'it-IT': 'Fino a 10 AI Agents attivi contemporaneamente'
                }
              }
            ]
          : []),
        {
          title: {
            'en-EN': 'Real time news (Sentiment)',
            'it-IT': 'News in tempo reale (Sentiment)'
          }
        },
        {
          title: {
            'en-EN': '50K $ for Paper Trading (virtual mode)',
            'it-IT': 'Modalità Virtuale (50K$)'
          }
        },
        {
          title: {
            'en-EN': 'Early features release',
            'it-IT': 'Rilascio anticipato di features'
          }
        },
        {
          title: {
            'en-EN': 'Priority support',
            'it-IT': 'Supporto prioritario'
          }
        }
      ],
      isYearlyPromo: true
    }
  ];

  if (pricingPage) {
    Pricing.push({
      title: 'Private',
      subTitle: {
        'en-EN': '',
        'it-IT': ''
      },
      proTier: 5,
      price: [
        {
          value: 399.99
        }
      ],
      priceTime: 'annual',
      isFeatured: false,
      textUnderPrice: {
        texts: ['+5% performance fee'],
        footer: {
          'en-EN': 'quarterly payment',
          'it-IT': 'con pagamento trimestrale'
        }
      },
      featureTitle: {
        'en-EN': '',
        'it-IT': ''
      },
      features: [
        {
          title: {
            'en-EN': 'Investment over $25K',
            'it-IT': 'Investimento oltre i 25K$'
          }
        },
        {
          title: {
            'en-EN':
              'Greater computing power and operational speed thanks to dedicated servers',
            'it-IT':
              'Maggiore potenza computazionale e velocità operativa grazie a server dedicati'
          }
        },

        ...(inTestABPortfolio
          ? [
              {
                title: {
                  'en-EN':
                    'AI Portfolio: Market Leaders, High Volume, Discovery Altcoins',
                  'it-IT':
                    'AI Portfolio: Market Leaders, High Volume, Discovery Altcoins'
                }
              }
            ]
          : [
              {
                title: {
                  'en-EN': 'AI Portfolio',
                  'it-IT': 'AI Portfolio'
                },
                beforeIcon: { name: 'star', variant: 'success' }
              }
            ]),
        {
          title: {
            'en-EN': 'Real time news (Sentiment)',
            'it-IT': 'News in tempo reale (Sentiment)'
          }
        },
        {
          title: {
            'en-EN': 'Paper Trading (100K$)',
            'it-IT': 'Modalità Virtuale (100K$)'
          }
        },
        {
          title: {
            'en-EN': 'Early features release',
            'it-IT': 'Rilascio anticipato di features'
          }
        },
        {
          title: {
            'en-EN': 'User dedicated support',
            'it-IT': 'Supporto utente dedicato'
          }
        }
      ],
      isYearlyPromo: false
    });
  }

  return Pricing;
};

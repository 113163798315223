import React, { useContext, useState } from 'react';
import { Row, Form, Col } from 'react-bootstrap';
import { CreateBot as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import BacktestBarChart from 'pages/CreateBot/Portfolio/Backtesting/BacktestBarChart';
import BacktestTable from 'pages/CreateBot/Portfolio/Backtesting/BacktestTable';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const MonthlyBacktest = ({
  mom,
  start_date,
  simulation_periods,
  showSingleYear = null,
  selectedMonth = null,
  isPortfolio = false
}) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const isMobile = window.innerWidth < 768;
  const isSimulation = simulation_periods > 0;

  // let bot = createBotList.find(bot => bot.id === id);
  // const { mom, start_date, simulation_periods } = bot.backtestData;
  const capital = isPortfolio ? '10.000' : '1.000';

  const convertToMonthlyReturns = (returnsArray, startDate) => {
    const startDateObj = new Date(startDate);
    const startYear = startDateObj.getFullYear();
    const startMonth = startDateObj.getMonth();
    const monthlyResults = {};
    let currentYear = startYear;
    let currentMonth = startMonth;

    for (let i = 0; i < returnsArray.length; i++) {
      if (!monthlyResults[currentYear]) {
        monthlyResults[currentYear] = Array(12).fill(0);
      }

      monthlyResults[currentYear][currentMonth] = returnsArray[i];
      currentMonth++;

      if (currentMonth > 11) {
        currentYear++;
        currentMonth = 0;
      }
    }

    const maxYear = Math.max(
      Object.keys(monthlyResults).map(year => parseInt(year))
    );
    while (currentYear <= maxYear) {
      if (!monthlyResults[currentYear]) {
        monthlyResults[currentYear] = Array(12).fill(0);
      }
      currentYear++;
    }
    let tmpIntArray = Object.keys(monthlyResults).map(year => parseInt(year));
    const lastYear = Math.max(...tmpIntArray);
    while (monthlyResults[lastYear].length < 12) {
      monthlyResults[lastYear].push(0);
    }

    return monthlyResults;
  };

  const calculateEndDate = (startDate, monthsOfSimulation) => {
    const startDateObj = new Date(startDate);
    const startYear = startDateObj.getFullYear();
    const startMonth = startDateObj.getMonth();

    let endMonth = startMonth + monthsOfSimulation;
    let endYear = String(startYear + Math.floor(endMonth / 12));
    endMonth = endMonth % 12;

    return { endYear, endMonth: isSimulation ? endMonth + 1 : 0 };
  };

  const defaultMothlyReturns = {
    2022: [
      -4.29, 1.47, 2.95, -1.4, 1.36, 2.91, 1.25, -1.64, 4.22, 5.85, -4.74, 1.89
    ],
    2023: [
      5.1, -1.68, 3.45, -1.6, 1.71, 3.36, -1.49, 1.85, 4.87, 7.0, -5.56, 2.11
    ],
    2024: [6.4, -1.99, 4.07, -2.0, 2.18, 3.97, 0, 0, 0, 0, 0, 0]
  };

  const monthlyReturns =
    convertToMonthlyReturns(mom, start_date) || defaultMothlyReturns;
  const endSimulation = calculateEndDate(start_date, simulation_periods) || {
    endYear: '2023',
    endMonth: 9
  };

  let tmpIntArray = Object.keys(monthlyReturns).map(year => parseInt(year));
  const lastYear = Math.max(...tmpIntArray);

  const [selectedYear, setSelectedYear] = useState(String(lastYear));

  const getTotalForYear = (data, year) => {
    if (!data[year]) return 0;
    let result = 100;
    for (const value of data[year]) {
      result *= 1 + value / 100;
    }
    return (result - 100).toFixed(2);
  };

  const handleSelectChange = event => {
    setSelectedYear(event.target.value);
  };

  return (
    <>
      <Row>
        <Col>
          {isMobile && !showSingleYear && (
            <Form.Select onChange={handleSelectChange} className="my-3">
              {Object.keys(monthlyReturns).map(year => (
                <option key={year} value={year}>
                  {textLang.year[lang] + ': ' + year}
                </option>
              ))}
            </Form.Select>
          )}

          <div className="py-3">
            <BacktestBarChart
              data={
                isMobile || showSingleYear
                  ? {
                      [showSingleYear || selectedYear]:
                        monthlyReturns[showSingleYear || selectedYear]
                    }
                  : monthlyReturns
              }
              showSingleYear={showSingleYear}
              getTotalForYear={getTotalForYear}
              setSelectedYear={setSelectedYear}
              selectedMonth={selectedMonth}
            />
          </div>
          <BacktestTable
            data={monthlyReturns}
            getTotalForYear={getTotalForYear}
            selectedYear={showSingleYear || selectedYear}
            endSimulation={endSimulation}
            selectedMonth={selectedMonth}
          />
        </Col>
      </Row>
      {isSimulation && (
        <Row>
          <Col>
            <small>
              {textLang.finalDisclaimerFirst[lang] +
                capital +
                textLang.finalDisclaimerSecond[lang]}
            </small>
          </Col>
        </Row>
      )}
      {isPortfolio && (
        <Row className="mt-2">
          <Col as={Link} to="/risk-explain">
            <small>
              <u>{textLang.riskDisclaimer[lang]}</u>
            </small>
          </Col>
        </Row>
      )}
    </>
  );
};

MonthlyBacktest.propTypes = {
  mom: PropTypes.object.isRequired,
  start_date: PropTypes.string.isRequired,
  simulation_periods: PropTypes.number.isRequired,
  showSingleYear: PropTypes.string.isRequired,
  isPortfolio: PropTypes.bool,
  selectedMonth: PropTypes.number
};

export default MonthlyBacktest;

import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Modal, Button, Spinner } from 'react-bootstrap';
import PricingDefaultHeader from './PricingModalHeader';
import PricingDefaultCard from './PricingModalCard';
import AppContext from 'context/Context';
import { Pricing as textLang } from 'staticData/languages';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import { endpointInterfaceV2 } from 'services/endpointInterface/endpointInterfaceV2';
import ConfirmMail from 'pages/common/modals/ConfirmMail';
import { firstProTier, secondProTier } from 'staticData/common';
import { useLocation, useHistory } from 'react-router-dom';
import { trackEvent } from 'services/externals/mixpanel';
import { StripeEmbedded } from 'services/externals/stripe/components';
import CreateBotModal from 'pages/common/modals/CreateBotModal';
import { calculateCoinsValue } from 'services/coins/functions';
import Flex from 'components/common/Flex';
import checkPrimaryLight from 'assets/img/animated-icons/check-primary-light.json';
import Lottie from 'lottie-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import { breakpoints, getColor } from 'helpers/utils';
import { getPricing } from '../pricingList';

const index = ({
  showModal,
  setShowModal,
  whatToDo = null,
  newProTier = 0,
  type = 'portfolio',
  virtualAction = { action: () => null, loading: false },
  showAlertFirstTier = false
}) => {
  const {
    config: { lang, savedBotParams },
    userInfos,
    coinValues
  } = useContext(AppContext);

  // get params in url
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const query_subscription = queryParams.get('subscription');
  const query_sub_type = queryParams.get('sub_type');
  const query_trial_days = queryParams.get('trial_days');
  const query_tier = queryParams.get('tier');
  const query_months = queryParams.get('months');
  const subMap = {
    1: 'Explorer',
    2: 'Investor'
  };

  const isNewSub = whatToDo === 'newSub';
  const isUpgrade = whatToDo === 'upgrade';
  const isVirtualTrial = whatToDo === 'virtualTrial';

  const [upgradePricePreview, setUpgradePricePreview] = useState();
  const [ConfirmMailShow, setConfirmMailShow] = useState(false);
  const [showCreateBot, setShowCreateBot] = useState(false);
  const [upgraded, setUpgraded] = useState(false);
  const [waitUpgrade, setWaitUpgrade] = useState(false);
  const [checkoutSession, setCheckoutSession] = useState('');
  const [loadingStripe, setLoadingStripe] = useState(0);
  const [redirectTimer, setRedirectTimer] = useState(null);
  const [selectedTier, setSelectedTier] = useState();
  const routerHistory = useHistory();
  const returnURL = type === 'bot' || type === 'portfolio' ? 'invest' : type;

  // Calculate current isYearly flag position, default to Year, monthly if a monthly subscription exists
  const currentIsYearly =
    !userInfos?.subscription ||
    !(userInfos && 'recurring_months' in userInfos.subscription) ||
    userInfos?.subscription.recurring_months === 12;

  const [isYearly, setIsYearly] = useState(currentIsYearly);

  const createSubscription = async tier => {
    setLoadingStripe(tier);
    let form = new FormData();
    form.append('provider', 'stripe');
    form.append('tier', tier);
    form.append('recurring_months', isYearly ? '12' : '1');
    form.append('tolt_referral', window.tolt_referral || '');
    form.append('success_url', returnURL);
    form.append('embedded', true);

    const resp = await endpointInterface(
      lang,
      'backend',
      'createUserSubscription',
      'post',
      true,
      form
    );
    setLoadingStripe(0);

    if (resp.responseStatus !== 200) {
      // TODO: Manage backend error, show a popup or something?
      console.error('Error occurred while creating subscription');
      return;
    }

    setCheckoutSession(resp.data.client_secret);
    return;
  };

  const getUpgradePreview = async () => {
    const resp = await endpointInterfaceV2({
      internalEndpoint: 'upgradePreview',
      httpMethod: 'post',
      usingCredentials: true,
      bodyParams: {
        tier: secondProTier,
        months: userInfos.subscription.recurring_months
      }
    });
    if (resp.validResponse) setUpgradePricePreview(resp.data.amount_due);
  };

  const upgradeSubscription = async (tier = 2, months = 12) => {
    setWaitUpgrade(true);
    let resp = await endpointInterfaceV2({
      internalEndpoint: 'upgradeSubscription',
      httpMethod: 'post',
      usingCredentials: true,
      bodyParams: {
        tier: tier,
        months: months,
        success_url: returnURL + '?sub_type=upgrade'
      }
    });
    if (resp.validResponse) window.location.href = resp.data.redirect_url;
    else setWaitUpgrade(false);
  };

  const upgradeTrial = async (tier = 2) => {
    setWaitUpgrade(true);
    let resp = await endpointInterfaceV2({
      internalEndpoint: 'upgradeTrial',
      httpMethod: 'post',
      usingCredentials: true,
      bodyParams: {
        tier: tier,
        months: userInfos?.subscription?.recurring_months
      }
    });
    if (resp.validResponse) {
      setUpgraded(true);
      // set timer to go to return url
      const timer = setTimeout(() => {
        window.location.href =
          '/' + returnURL + '?sub_type=upgrade&subscription=success';
      }, 5000);
      setRedirectTimer(timer);
    } else window.location.href = '/account';
    setWaitUpgrade(false);
  };

  const newSubButtonMap = {
    virtual: {
      // virtual trial
      text: userInfos.trial_allowed ? textLang.freeTrial : textLang.start,
      enabled: newProTier === 0 || newProTier === firstProTier,
      primary: newProTier === 0 || newProTier === firstProTier,
      show: true,
      loading: virtualAction.loading,
      onClick: async () => {
        const createError = await virtualAction.action();
        if (!createError) handleHideModal();
      }
    },
    1: {
      // pro_tier 1
      text: userInfos.trial_allowed ? textLang.freeTrial : textLang.start,
      enabled: newProTier === 0 || newProTier === firstProTier,
      primary: newProTier === 0 || newProTier === firstProTier,
      show: true,
      loading: loadingStripe === 1,
      onClick:
        userInfos.pro_tier === 0 ? () => createSubscription(1) : () => null
    },
    2: {
      // pro_tier 2
      text: userInfos.trial_allowed ? textLang.freeTrial : textLang.start,
      enabled: newProTier === 0 || newProTier === secondProTier,
      primary: newProTier === 0 || newProTier === secondProTier,
      show: true,
      loading: loadingStripe === 2,
      onClick:
        userInfos.pro_tier === 0 ? () => createSubscription(2) : () => null
    }
  };

  const handleUpgrade = () => {
    if (userInfos.subscription?.trialing) upgradeTrial();
    else upgradeSubscription();
  };

  const Pricing = getPricing(newProTier, isNewSub);

  if (isYearly)
    Pricing.map(pricing => {
      pricing.price = pricing.price.filter(item => !item.onlyMonthly);
    });
  else {
    Pricing.map(pricing => {
      pricing.price = pricing.price.filter(item => !item.onlyYearly);
      if (pricing.isYearlyPromo) {
        pricing.price.pop();
        pricing.isYearlyPromo = false;
      }
    });
  }

  const handleCloseClickTimer = () => {
    if (redirectTimer) {
      clearTimeout(redirectTimer);
    }
    window.location.href =
      '/' + returnURL + '?sub_type=upgrade&subscription=success';
  };

  const handleHideModal = () => {
    setShowModal(false);
    setCheckoutSession('');
    if (upgraded) handleCloseClickTimer();
  };

  // del timer during return
  useEffect(() => {
    return () => {
      if (redirectTimer) {
        clearTimeout(redirectTimer);
      }
    };
  }, [redirectTimer]);

  useEffect(async () => {
    if (!userInfos?.email_confirmed) setConfirmMailShow(true);
    // send data to mix panel if status = success in url
    if (query_subscription === 'success') {
      if (query_sub_type === 'upgrade')
        trackEvent('upgrade', {
          trial: userInfos?.subscription?.trialing || false,
          months: query_months || userInfos?.subscription?.recurring_months
        });
      else
        trackEvent('subscription', {
          trial_days: query_trial_days,
          subscription_type: subMap[String(query_tier)] || null,
          months: query_months
        });
      // Remove URL parameters after tracking
      const newQueryParams = new URLSearchParams(window.location.search);

      newQueryParams.delete('sub_type');
      newQueryParams.delete('trial_days');
      newQueryParams.delete('tier');
      newQueryParams.delete('months');
      newQueryParams.set('subscription', 'accepted');

      routerHistory.replace({
        pathname: window.location.pathname,
        search: newQueryParams.toString()
      });
      // check if there is a bot to start
      if (
        (userInfos.pro_tier > firstProTier && savedBotParams) ||
        (userInfos.pro_tier === firstProTier &&
          savedBotParams?.botCategory === 'bot' &&
          savedBotParams &&
          calculateCoinsValue(
            savedBotParams.wallet || { USDT: 0 },
            coinValues.current
          ) < 10000)
      ) {
        // Create a Date object from the specified string
        const specificDate = new Date(savedBotParams.time);
        // Add one hour (3600000 milliseconds) to the specified date
        const specificDatePlusOneHour = new Date(
          specificDate.getTime() + 3600000
        );
        // Get the current date and time
        const now = new Date();
        // Compare the current date with the specified date plus one hour
        if (now <= specificDatePlusOneHour) setShowCreateBot(true);
      }
    }
  }, []);

  useEffect(async () => {
    setSelectedTier(
      isNewSub
        ? newProTier !== 0
          ? newProTier
          : firstProTier
        : isVirtualTrial
        ? 'virtual'
        : undefined
    );
    // get upgrade price preview
    if (
      showModal &&
      userInfos.pro_tier === firstProTier &&
      !userInfos.subscription?.trialing
    )
      getUpgradePreview();
  }, [showModal]);

  return (
    <>
      {userInfos && (
        <>
          <ConfirmMail
            ConfirmMailShow={ConfirmMailShow}
            setConfirmMailShow={setConfirmMailShow}
            step={0}
            withHeader={false}
            userCanClose={false}
          />
          {/* Create bot */}
          <CreateBotModal
            showModal={showCreateBot}
            setShowModal={setShowCreateBot}
          />
        </>
      )}
      {checkoutSession ? (
        <Modal
          show={showModal}
          onHide={() => handleHideModal()}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton closeVariant="white">
            <div
              onClick={() => setCheckoutSession('')}
              className="pointerCursor"
            >
              <FontAwesomeIcon icon={'fa-arrow-left'} className="me-2" />
              {textLang.back[lang]}
            </div>
          </Modal.Header>
          <Modal.Body
            style={
              window.innerWidth > breakpoints['sm']
                ? { minHeight: '700px' }
                : {}
            }
          >
            <StripeEmbedded
              checkoutSession={checkoutSession}
              style={
                window.innerWidth > breakpoints['sm']
                  ? { minWidth: '992px' }
                  : {}
              }
            />
          </Modal.Body>
        </Modal>
      ) : (
        <Modal
          show={showModal && !ConfirmMailShow}
          onHide={() => handleHideModal()}
          size={isVirtualTrial ? 'lg' : 'xl'}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          {isVirtualTrial && (
            <Modal.Body>
              <Row className="g-0 justify-content-center">
                <Col
                  style={{
                    border: `1px solid ${getColor('primary')}`,
                    borderRadius: '5px'
                  }}
                  className="text-center px-5 pb-5"
                >
                  <Flex
                    justifyContent={
                      window.innerWidth < breakpoints['sm'] ? 'center' : 'end'
                    }
                    className="mt-2 mb-4"
                  >
                    <SoftBadge
                      bg={'info'}
                      className="fw-medium text-decoration-none me-2"
                    >
                      {textLang.virtualBadge[lang]}
                    </SoftBadge>
                  </Flex>
                  <h3 className="mb-0">{textLang.virtualTitle[lang]}</h3>
                  <div
                    className="d-flex flex-column align-items-center justify-content-center"
                    style={{
                      minHeight: '70px'
                    }}
                  >
                    <div
                      key={index}
                      className="mt-1 mb-0 pb-0 d-flex flex-row justify-content-center position-relative"
                    >
                      <p className="p-0 m-0 fw-medium fs-1 text-1000">
                        {textLang.virtualSub[lang]}
                      </p>
                    </div>
                  </div>
                  <p>{textLang.virtualText[lang]}</p>
                </Col>
                <Row className="mt-5 mb-3 justify-content-center">
                  <Col as={Flex} className="justify-content-center">
                    <Button
                      variant={'primary'}
                      onClick={() => newSubButtonMap[selectedTier].onClick()}
                      disabled={
                        !newSubButtonMap[selectedTier]?.enabled ||
                        newSubButtonMap[selectedTier]?.loading
                      }
                    >
                      {newSubButtonMap[selectedTier]?.loading && (
                        <Spinner
                          className="me-2"
                          style={{ width: '1rem', height: '1rem' }}
                        />
                      )}
                      {newSubButtonMap[selectedTier]?.text?.[lang] ||
                        newSubButtonMap[selectedTier]?.text}
                    </Button>
                  </Col>
                </Row>
                <Row className="d-sm-none mb-3 justify-content-center">
                  <Col as={Flex} className="justify-content-center">
                    <Button
                      variant={'outline-primary'}
                      onClick={handleHideModal}
                    >
                      {textLang.back[lang]}
                    </Button>
                  </Col>
                </Row>
              </Row>
            </Modal.Body>
          )}
          {isNewSub && (
            <Modal.Body>
              <PricingDefaultHeader
                isYearly={isYearly}
                setIsYearly={setIsYearly}
                disabled={
                  userInfos?.pro_tier === 1 &&
                  userInfos?.subscription?.recurring_months === 12
                }
              />
              <Row className="g-0 justify-content-center">
                {Pricing.map((pricing, index) => (
                  <PricingDefaultCard
                    id={pricing.proTier}
                    key={index}
                    pricing={pricing}
                    selectedID={selectedTier}
                    setSelectedID={setSelectedTier}
                    type={type}
                    style={
                      selectedTier !== pricing.proTier
                        ? { transform: 'scale(0.9)' }
                        : {}
                    }
                    showAlertFirstTier={showAlertFirstTier}
                  />
                ))}
                <Row className="mt-5 mb-3 justify-content-center">
                  <Col as={Flex} className="justify-content-center">
                    <Button
                      variant={'primary'}
                      onClick={() => newSubButtonMap[selectedTier].onClick()}
                      disabled={
                        !newSubButtonMap[selectedTier]?.enabled ||
                        newSubButtonMap[selectedTier]?.loading
                      }
                    >
                      {newSubButtonMap[selectedTier]?.loading && (
                        <Spinner
                          className="me-2"
                          style={{ width: '1rem', height: '1rem' }}
                        />
                      )}
                      {newSubButtonMap[selectedTier]?.text?.[lang] ||
                        newSubButtonMap[selectedTier]?.text}
                    </Button>
                  </Col>
                </Row>
                <Row className="d-sm-none mb-3 justify-content-center">
                  <Col as={Flex} className="justify-content-center">
                    <Button
                      variant={'outline-primary'}
                      onClick={handleHideModal}
                    >
                      {textLang.back[lang]}
                    </Button>
                  </Col>
                </Row>
              </Row>
            </Modal.Body>
          )}
          {isUpgrade &&
            (!upgraded ? (
              <>
                <Modal.Header closeButton closeVariant="white">
                  <Modal.Title as="h5">
                    {userInfos?.subscription?.trialing
                      ? textLang.freeUpgradeTitle[lang]
                      : textLang.upgradeTitle[lang]}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row className="g-0 justify-content-center">
                    <PricingDefaultCard
                      id={1}
                      pricing={Pricing[0]}
                      selectedID={2}
                      type={type}
                      style={{ transform: 'scale(0.8)' }}
                      showAlertFirstTier={showAlertFirstTier}
                    />
                    <Col xs="auto" className="d-flex align-items-center pe-3">
                      <FontAwesomeIcon
                        icon={`arrow-${
                          window.innerWidth > breakpoints['sm']
                            ? 'right'
                            : 'down'
                        }`}
                        style={{ fontSize: '2rem' }}
                      />
                    </Col>
                    <PricingDefaultCard
                      id={2}
                      pricing={Pricing[1]}
                      selectedID={2}
                      type={type}
                    />
                    <Row className="mt-5 mb-3 justify-content-center">
                      {upgradePricePreview != null && (
                        <p className="text-center mb-3">
                          {textLang.upgradePreviewText[lang]}
                          <b>€{upgradePricePreview.toFixed(2)}</b>
                          {process.env.REACT_APP_ENV === 'DEV' && ' \u{1F525}'}
                        </p>
                      )}
                      <Col as={Flex} className="justify-content-center">
                        <Button
                          variant={'primary'}
                          onClick={handleUpgrade}
                          disabled={waitUpgrade}
                        >
                          {waitUpgrade && (
                            <Spinner
                              className="me-2"
                              style={{ width: '1rem', height: '1rem' }}
                            />
                          )}
                          {userInfos?.subscription?.trialing
                            ? textLang.freeUpgradeButton[lang]
                            : textLang.upgradeButton[lang]}
                        </Button>
                      </Col>
                    </Row>
                  </Row>
                </Modal.Body>
              </>
            ) : (
              <>
                <Modal.Header closeButton closeVariant="white">
                  <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row className="align-items-center">
                    <Col lg={12} className="d-flex justify-content-center">
                      <Lottie
                        animationData={checkPrimaryLight}
                        loop={true}
                        style={{ height: '120px', width: '120px' }}
                      />
                    </Col>
                  </Row>
                  <h4 className="text-center font-weight-bold">
                    {textLang.upgradeOkText[lang]}
                  </h4>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={handleCloseClickTimer}>
                    {textLang.upgradeTextButton[lang]}
                  </Button>
                </Modal.Footer>
              </>
            ))}
        </Modal>
      )}
    </>
  );
};

export default index;

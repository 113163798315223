import React, { useState, useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Card, Spinner } from 'react-bootstrap';
import { VirtualMode as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import SoftBadge from 'components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { walletImgRender } from 'services/coins/components';
import { currencyMap } from 'services/coins/common';
import { calculateCoinsValue } from 'services/coins/functions';
import { portfolioList } from 'pages/CreateBot/staticData/portfolioList';
import Flex from 'components/common/Flex';
import ButtonGrid from 'pages/CreateBot/ButtonGrid';
import HalfDoughnutChart from 'pages/common/HalfChartPortfolio';
import { coinsData } from 'services/coins/common';
import SimpleBarReact from 'simplebar-react';
import CardDropdown from 'pages/common/portfolioRow/CardDropdown';
import LinePaymentChart from 'pages/common/BotWallet/LinePaymentChart';
import AddCapital from 'pages/common/portfolioRow/AddCapital';
import LottieBotLazy from 'services/lazyLoading/LottieBotLazy';
import classNames from 'classnames';
import { breakpoints } from 'helpers/utils';
import { getBreakpoint } from 'staticData/common';

const LinePaymentButtonMap = {
  '24h': '1d_timepoints',
  '7d': '7d_timepoints',
  '1m': '30d_timepoints',
  '6m': '6m_timepoints',
  All: 'all_timepoints'
};
const chartStyle = {
  xs: { height: '200px' },
  sm: { height: '300px' },
  md: { height: '300px' },
  lg: { height: '300px' },
  xl: { height: '300px' },
  xxl: { height: '300px' }
};
const convertWallet = wallet => {
  return Object.entries(wallet).map(([timestamp, values]) => ({
    ts: timestamp,
    EUR: values.EUR,
    USDT: values.USDT
  }));
};

const getColorAndCaret = num => {
  if (Number(num) < 0) return ['text-danger', 'caret-down'];
  if (Number(num) > 0) return ['text-success', 'caret-up'];
  return ['text-dark', 'minus'];
};

const PortfolioRowData = ({
  porfolioData = {},
  dynamicCoins,
  showChart = true
}) => {
  const {
    config: { lang, currency }
  } = useContext(AppContext);

  const [loaded, setLoaded] = useState(false);
  const [bot, setBot] = useState({});
  const [showedWallet, setShowedWallet] = useState({});
  const [granularity, setGranularity] = useState('All');
  const [riskLevel, setRiskLevel] = useState(0);
  const chartHeigth = 18;
  const [initialEquivalent, setInitialEquivalent] = useState(0);
  const [addedCapital, setAddedCapital] = useState(0);
  const chartRef = useRef(null);
  const [lastValue, setLastValue] = useState(0);
  const [performance, setPerformance] = useState(0);
  const [performanceColorAndCaret, setPerformanceColorAndCaret] = useState();
  const [coinListItems, setCoinListItems] = useState([]);
  const [donutData, setDonutData] = useState([]);
  const [portfolioDetails, setPortfolioDetails] = useState();

  const botStatusColor = status => {
    if (status.toUpperCase() === 'ACTIVE') return 'success';
    if (status.toUpperCase() === 'ERROR') return 'danger';
    else return 'warning';
  };

  const handleButtonClick = key => {
    if (Object.keys(bot.wallet?.[LinePaymentButtonMap[key]]).length === 0)
      return;
    setGranularity(key);
    setShowedWallet(convertWallet(bot.wallet?.[LinePaymentButtonMap[key]]));
  };

  const loadDonutData = bot => {
    setDonutData(
      Object.keys(dynamicCoins).length
        ? {
            labels: Object.keys(bot.last_stat.wallet),
            datasets: [
              {
                data: Object.keys(bot.last_stat.wallet).map(key =>
                  Number(
                    (
                      (bot.last_stat.wallet[key] * dynamicCoins[key] * 100) /
                      calculateCoinsValue(bot.last_stat.wallet, dynamicCoins)
                    ).toFixed(2)
                  )
                ),
                backgroundColor: Object.keys(bot.last_stat.wallet).map(
                  key => coinsData[key].color
                ),
                borderColor: Object.keys(bot.last_stat.wallet).map(
                  key => coinsData[key].color
                )
              }
            ]
          }
        : {
            labels: [],
            datasets: [
              {
                data: [],
                backgroundColor: [],
                borderColor: []
              }
            ]
          }
    );
  };

  const loadCoinListItems = bot => {
    setCoinListItems(
      Object.keys(dynamicCoins).length ? (
        Object.keys(bot.last_stat.wallet)
          .filter(key => bot.last_stat.wallet[key] > 0)
          .sort(
            (a, b) =>
              bot.last_stat.wallet[b] * dynamicCoins[b] -
              bot.last_stat.wallet[a] * dynamicCoins[a]
          )
          .map(key => (
            <>
              {bot.last_stat.wallet[key] > 0 && (
                <p key={key} className="pe-2">
                  <Flex
                    style={{ color: coinsData[key].color }}
                    className="justify-content-between"
                  >
                    <Flex>
                      {walletImgRender([key], 'me-2')}
                      {coinsData[key].name}
                    </Flex>
                    <span className="text-900 ps-2 fw-medium fs--1">
                      {currency}
                      {(bot.last_stat.wallet[key] * dynamicCoins[key]).toFixed(
                        2
                      )}
                      <span className="text-600 ms-1">
                        (
                        {(
                          (bot.last_stat.wallet[key] *
                            dynamicCoins[key] *
                            100) /
                          calculateCoinsValue(
                            bot.last_stat.wallet,
                            dynamicCoins
                          )
                        ).toFixed(2)}
                        %)
                      </span>
                    </span>
                  </Flex>
                </p>
              )}
            </>
          ))
      ) : (
        <></>
      )
    );
  };

  useEffect(() => {
    if (!loaded && Object.keys(porfolioData).length) {
      const localBot = porfolioData;
      setBot(localBot);
      const botType = localBot.bot_details.type;
      const { id, name, bgStyle, backendMinLevels, riskLevels } =
        portfolioList.find(localBot => String(localBot.id) === String(botType));
      setPortfolioDetails({ id, name, bgStyle });
      setShowedWallet(convertWallet(localBot.wallet?.all_timepoints || {}));
      setRiskLevel(
        riskLevels[backendMinLevels.indexOf(localBot.bot_details.min_level)]
      );
      const localInitialEquivalent =
        localBot.bot_details.initial_eqv[currencyMap[currency]];
      setInitialEquivalent(localInitialEquivalent);
      const localAddedCapital = localBot.added_capital;
      setAddedCapital(localAddedCapital);
      const localLastValue = localBot.last_stat
        ? Object.keys(dynamicCoins).length
          ? calculateCoinsValue(localBot.last_stat.wallet, dynamicCoins)
          : localBot.last_stat.eqv[currencyMap[currency]]
        : localInitialEquivalent;
      setLastValue(localLastValue);
      const localPerformance =
        localLastValue - (localInitialEquivalent + localAddedCapital);
      setPerformance(localPerformance);
      setPerformanceColorAndCaret(getColorAndCaret(localPerformance));
      loadDonutData(localBot);
      loadCoinListItems(localBot);
      setLoaded(true);
    }
  }, [Object.keys(porfolioData)]);

  const borderStyle =
    window.innerWidth > breakpoints['sm'] ? 'borderRight' : 'borderBottom';

  return (
    <Row className="my-3">
      <Col>
        <Card style={{ backgroundImage: portfolioDetails?.bgStyle || '' }}>
          <Card.Body>
            <Row>
              <Col
                md={8}
                style={{
                  [borderStyle]: 'var(--falcon-500) 1px solid'
                }}
              >
                <Row className="mb-4">
                  <Col xs={4} as={Flex} className="justify-content-center">
                    {loaded ? (
                      <LottieBotLazy
                        type={portfolioDetails.id || ''}
                        loop={true}
                        style={{ width: '100%' }}
                      />
                    ) : (
                      <Flex className="my-5 align-items-center justify-content-center">
                        <Spinner />
                      </Flex>
                    )}
                  </Col>
                  <Col xs={8} as={Flex} className="align-items-center">
                    <div>
                      <small>AI Portfolio</small>
                      {loaded && <h4>{portfolioDetails?.name || ''}</h4>}
                      <Flex className="align-items-center">
                        <h6 className="mb-0 me-2">
                          {textLang.riskLevel[lang]}
                        </h6>

                        {loaded && (
                          <ButtonGrid
                            actives={[riskLevel]}
                            click={false}
                            buttonSize={'sm'}
                          />
                        )}
                      </Flex>
                    </div>
                  </Col>
                </Row>
                {showChart &&
                  (!loaded ||
                    (loaded &&
                      bot.wallet?.all_timepoints &&
                      Object.keys(bot.wallet?.all_timepoints).length > 0)) && (
                    <>
                      <Row className="mx-sm-5 mb-1 mb-sm-4">
                        <Col
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          {Object.keys(LinePaymentButtonMap).map(key => (
                            <p
                              key={key}
                              className={classNames('', {
                                pointerCursor:
                                  loaded &&
                                  Object.keys(
                                    bot.wallet?.[LinePaymentButtonMap[key]]
                                  ).length,
                                'fw-bold': granularity === key
                              })}
                              style={{
                                borderBottom:
                                  granularity === key ? '2px solid' : 'none',
                                opacity:
                                  loaded &&
                                  Object.keys(
                                    bot.wallet?.[LinePaymentButtonMap[key]]
                                  ).length === 0
                                    ? '.5'
                                    : '1'
                              }}
                              onClick={() => handleButtonClick(key)}
                            >
                              {key}
                            </p>
                          ))}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {loaded ? (
                            <LinePaymentChart
                              displayedWallet={showedWallet}
                              granularity={granularity}
                              ref={chartRef}
                              style={chartStyle}
                              events={bot?.snapshots || []}
                              handleCheckpointData={() => null}
                              setShowPercentage={() => null}
                              simplifyChart={true}
                              bgStyle={true}
                            />
                          ) : (
                            <Flex
                              className="justify-content-center align-items-center"
                              style={
                                chartStyle[getBreakpoint(window.innerWidth)]
                              }
                            >
                              <Spinner />
                            </Flex>
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
                <Row className="mt-4 mb-2">
                  <Col xs={4} className="text-center">
                    <p className="mt-2 mb-0">{textLang.invested[lang]}</p>
                    {loaded ? (
                      <p className="mt-2 fs-sm-3">
                        <b className={'text-dark'}>
                          {currency}
                          {(initialEquivalent + addedCapital).toFixed(2)}
                        </b>
                      </p>
                    ) : (
                      <Flex className="justify-content-center mt-2">
                        <Spinner />
                      </Flex>
                    )}
                  </Col>
                  <Col xs={4} className="text-center">
                    <p className="mt-2 mb-0">{textLang.managed[lang]}</p>
                    {loaded ? (
                      <p className="mt-2 fs-sm-3">
                        <b className={'text-dark'}>
                          {currency}
                          {lastValue.toFixed(2)}
                        </b>
                      </p>
                    ) : (
                      <Flex className="justify-content-center mt-2">
                        <Spinner />
                      </Flex>
                    )}
                  </Col>
                  <Col xs={4} className="text-center">
                    <p className="mt-2 mb-0">
                      <span className="d-none d-sm-block">
                        {textLang.performance[lang]}
                      </span>
                      <span className="d-sm-none">
                        <FontAwesomeIcon
                          icon="arrow-up"
                          size="xs"
                          className="text-success"
                        />
                        <FontAwesomeIcon
                          icon="arrow-down"
                          size="xs"
                          className="text-danger mt-1"
                        />
                      </span>
                    </p>
                    {loaded ? (
                      <p className="mt-2 fs-sm-3">
                        <b className={performanceColorAndCaret[0]}>
                          {Number(performance) !== 0 && (
                            <span className="me-1">
                              <FontAwesomeIcon
                                icon={performanceColorAndCaret[1]}
                              />
                            </span>
                          )}
                          {currency}
                          {Math.abs(Number(performance).toFixed(2))}
                        </b>
                      </p>
                    ) : (
                      <Flex className="justify-content-center mt-2">
                        <Spinner />
                      </Flex>
                    )}
                  </Col>
                </Row>
              </Col>
              {loaded ? (
                <Col md={4}>
                  <Flex className="justify-content-center">
                    <div
                      style={{
                        width: 'fit-content'
                      }}
                    >
                      <div
                        className="text-center pb-3"
                        style={{
                          maxWidth: `${chartHeigth}rem`
                        }}
                      >
                        <HalfDoughnutChart
                          data={donutData}
                          style={{ marginTop: '-2rem' }}
                        />
                        <h4 className="d-inline-block mb-0 mt-n4 fs-1 fw-medium font-sans-serif">
                          {textLang.now[lang]}
                        </h4>
                      </div>
                      <SimpleBarReact
                        style={{
                          maxHeight: `${
                            window.innerWidth > breakpoints['sm'] && showChart
                              ? chartHeigth * 1.5
                              : chartHeigth * 0.65
                          }rem`,
                          width: '100%'
                        }}
                      >
                        <div className="mt-3">{coinListItems}</div>
                      </SimpleBarReact>
                    </div>
                  </Flex>
                </Col>
              ) : (
                <Col
                  md={4}
                  as={Flex}
                  className="justify-content-center align-items-center"
                >
                  <Spinner />
                </Col>
              )}
            </Row>
          </Card.Body>

          {/* BOT STATUS: Top Left */}
          {loaded && (
            <div style={{ position: 'absolute', top: '10px', left: '10px' }}>
              <SoftBadge pill bg={botStatusColor(bot.bot_details.status)}>
                {bot.bot_details.status.toUpperCase() === 'ACTIVE'
                  ? textLang.active[lang]
                  : bot.bot_details.status.toUpperCase() === 'PAUSED'
                  ? textLang.paused[lang]
                  : textLang.error[lang]}
              </SoftBadge>
            </div>
          )}
          {/* BOT info: Top Right */}
          {loaded && (
            <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
              <Flex className="align-items-center">
                <AddCapital portfolio={bot} />
                <CardDropdown portfolio={bot} managed={lastValue} />
              </Flex>
            </div>
          )}
        </Card>
      </Col>
    </Row>
  );
};

PortfolioRowData.propTypes = {
  porfolioData: PropTypes.object.isRequired,
  exchange: PropTypes.object,
  dynamicCoins: PropTypes.object,
  showChart: PropTypes.bool
};

export default PortfolioRowData;

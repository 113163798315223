import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Col, Accordion, Form, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import AppContext from 'context/Context';
import { Pricing as textLang } from 'staticData/languages';
import { getColor } from 'helpers/utils';
import { isInTestAB, portfolioTest } from 'services/testAB/functions';
import 'assets/scss/custom/pricing.scss';
import { firstProTier, secondProTier } from 'staticData/common';

const PricingDefaultCard = ({
  pricing: {
    title,
    titleColor = '',
    titleIcon = '',
    subTitle,
    price,
    enabled,
    featureTitle,
    features,
    isYearlyPromo,
    textUnderPrice
  },
  id,
  selectedID,
  setSelectedID = () => null,
  type,
  style = {},
  showAlertFirstTier = false
}) => {
  const {
    config: { lang },
    userInfos
  } = useContext(AppContext);
  const subTextLang = textLang.pricingCard;

  const colStyle = {
    ...style,
    ...(enabled ? {} : { opacity: '.5' })
  };

  const FeatureList = ({ features, lang }) => (
    <ul className="list-unstyled mt-3">
      {features.map(feature => (
        <li className="py-1 fs--1" key={feature.title[lang]}>
          {feature.beforeIcon?.name ? (
            <>
              <FontAwesomeIcon
                icon={feature.beforeIcon.name}
                className={`me-2 text-${
                  feature.beforeIcon?.color || 'success'
                }`}
                size={'sm'}
              />{' '}
            </>
          ) : (
            <>
              <FontAwesomeIcon icon="check" className="me-2 text-success" />{' '}
            </>
          )}
          {feature.title[lang]}{' '}
          {feature.icon && <FontAwesomeIcon icon={feature.icon} size={'sm'} />}
          {feature.tag && (
            <SoftBadge pill bg={feature.tag.type}>
              {feature.tag.label[lang]}
            </SoftBadge>
          )}
        </li>
      ))}
    </ul>
  );

  const Header = (
    <>
      <h3
        className="fw-normal mt-2 my-sm-0 d-flex justify-content-center"
        style={{ color: titleColor }}
      >
        {title[lang] || title || ''}
        {titleIcon && (
          <FontAwesomeIcon icon={titleIcon} className="ps-2" size={'md'} />
        )}
      </h3>
      {subTitle[lang] && (
        <div
          className="m-0 d-flex align-items-center justify-content-center"
          style={{ minHeight: '60px' }}
        >
          <p className="mt-2 mb-0">{subTitle[lang]}</p>
        </div>
      )}
    </>
  );

  FeatureList.propTypes = {
    features: PropTypes.object,
    lang: PropTypes.string
  };

  const Prices = (
    <>
      {price.map((singlePrice, index) => (
        <div
          key={index}
          className="mt-1 mb-0 pb-0 d-flex flex-row justify-content-center position-relative"
        >
          <p
            className={
              index == price.length - 1
                ? 'p-0 m-0 ms-5 fw-medium fs-3 text-1000'
                : 'p-0 m-0 ms-5 fw-medium text-700 text-decoration-line-through'
            }
          >
            € {singlePrice.value}
          </p>
          <small
            className={
              index == price.length - 1
                ? 'mt-1 me-5 fs--1 text-700'
                : 'fs--2 me-5 text-700'
            }
          >
            / {subTextLang.month[lang]}
          </small>
          {singlePrice.tag && singlePrice.tag[lang] && (
            <SoftBadge
              className="p-1 discount-tag"
              pill
              style={{
                backgroundColor: singlePrice.tagBg,
                color: singlePrice.tagColor
              }}
            >
              <p
                style={
                  index == price.length - 1
                    ? { fontSize: '13px' }
                    : { fontSize: '8px' }
                }
                className="m-0 p-0"
              >
                {singlePrice.tag[lang]}
              </p>
            </SoftBadge>
          )}
        </div>
      ))}
      {textUnderPrice?.texts.map((text, index) => (
        <p key={index} className="mb-0 fs--1">
          {text}
        </p>
      ))}
      {textUnderPrice?.footer && (
        <small className="mb-0">{textUnderPrice.footer[lang]}</small>
      )}
      {isYearlyPromo && (
        <small className="mt-0 pt-0">{subTextLang.annualPayment[lang]} </small>
      )}
    </>
  );

  const Features = (
    <div className={`text-start px-sm-4 pt-2 py-sm-2`}>
      <h5 className="fw-medium fs-0">{featureTitle[lang]}</h5>
      <span className="d-none d-sm-block">
        <FeatureList features={features} lang={lang} />
      </span>
      {Object.keys(features).length > 0 && (
        <Accordion className="d-sm-none me-3" defaultActiveKey="">
          <Accordion.Item>
            <Accordion.Header>
              {textLang.accordionHeader[lang]}
            </Accordion.Header>
            <Accordion.Body>
              <FeatureList features={features} lang={lang} />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
    </div>
  );

  return (
    <Col lg={5} className="mt-3 px-0 mx-2" style={colStyle}>
      <Form.Check
        type="radio"
        id={`input-${id}`}
        className="mb-0 form-check radio-select px-5"
        style={{
          minHeight: '100%',
          border: `1px solid ${getColor(
            selectedID === id ? 'primary' : 'secondary'
          )}`,
          borderRadius: '5px'
        }}
      >
        <Form.Check.Label className="fw-bold d-block">
          <div
            className={
              'text-center ' +
              (userInfos?.pro_tier <= secondProTier ? 'pt-4' : 'py-4')
            }
          >
            <Form.Check.Input
              type="radio"
              name="inputRadio"
              defaultChecked={selectedID === id}
              onChange={() => setSelectedID(id)}
              disabled={!enabled}
            />
            {Header}
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              style={{
                minHeight: '110px'
              }}
            >
              {Array.isArray(price) ? Prices : <h5>{price[lang]}</h5>}
            </div>
          </div>
          <div className="">
            {!enabled &&
            textLang.alert?.[type]?.[lang] &&
            !isInTestAB(userInfos, portfolioTest) ? (
              <Alert variant="warning" className="me-3">
                <p
                  className="mb-0"
                  dangerouslySetInnerHTML={{
                    __html: textLang.alert?.[type]?.[lang] || ''
                  }}
                ></p>
              </Alert>
            ) : (
              showAlertFirstTier &&
              id === firstProTier && (
                <Alert variant="warning" className="me-3">
                  <p
                    className="mb-0"
                    dangerouslySetInnerHTML={{
                      __html: textLang.alert?.['noThisPortfolio']?.[lang] || ''
                    }}
                  ></p>
                </Alert>
              )
            )}
            {Features}
          </div>
        </Form.Check.Label>
      </Form.Check>
    </Col>
  );
};

PricingDefaultCard.propTypes = {
  pricing: PropTypes.object,
  id: PropTypes.number,
  selectedID: PropTypes.number,
  setSelectedID: PropTypes.func,
  type: PropTypes.string,
  style: PropTypes.object,
  showAlertFirstTier: PropTypes.bool
};

export default PricingDefaultCard;
